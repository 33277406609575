/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import schemaOrgJSONLD from "./schemaGenerator.js"

const defaultImage = '/social-networks.jpg';

const SEO = ({ title, description, image, breadcrumb }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    titleTemplate,
    name,
  } = site.siteMetadata
  const seo = {
    title: title || "",
    description: description || defaultDescription,
    image: `${image ? siteUrl + image : siteUrl + defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }
  const descriptionDecoded = seo.description
    .replace("&rsquo;", "'")
    .replace(/<[^>]+>/g, "")
  const json = schemaOrgJSONLD({
    breadcrumb,
    name,
    defaultTitle,
    title: seo.title,
    description: descriptionDecoded,
    image: seo.image,
    url: seo.url,
    siteUrl,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang: "fr",
      }}
      bodyAttributes={{
        class: "min-h-screen bg-dark text-white",
      }}
      defaultTitle={defaultTitle}
      title={seo.title}
      titleTemplate={titleTemplate}
      link={[
        {
          rel: "canonical",
          href: seo.url,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: descriptionDecoded,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: descriptionDecoded,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "og:image",
          content: seo.image,
        },
        {
          property: "og:locale",
          content: "fr_FR",
        },
        {
          property: "og:site_name",
          content: "École de conduite Yvan Blin",
        },
        {
          property: "og:image:width",
          content: "1240",
        },
        {
          property: "og:image:height",
          content: "520",
        },
        {
          name: "apple-mobile-web-app-capable",
          content: "yes",
        },
      ]}
    >
      <script type="application/ld+json">{JSON.stringify(json)}</script>
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle
        titleTemplate
        defaultDescription: description
        siteUrl: url
        name
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  type: "default",
}
