import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import signature from "../images/signature.svg"

import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <div className="min-h-screen-2 container px-6 lg:px-4 font-normal">
        <Header />
        <main>{children}</main>
      </div>

      <footer className="pt-16 mt-16 font-normal border-t border-white border-opacity-50">
        <div className="container px-6 lg:px-4 grid md:grid-cols-4">
          <div className="col-span-2 text-center md:text-left">
            <img
              src={signature}
              width={200}
              alt="Logo - Yvan Blin"
              className="inline-block mb-16 md:mb-0"
            />
          </div>
          <div className="text-center md:text-left">
            <Link className="block py-2 md:py-0" to="/l-auto-ecole/">L'auto-école</Link>
            <Link className="block py-2 md:py-0" to="/les-horaires/tests-de-code">Les horaires</Link>
            <Link className="block py-2 md:py-0" to="/nos-formations/">Nos formations</Link>
            <Link className="block py-2 md:py-0" to="/le-label/">Le Label</Link>
          </div>
          <div className="text-center md:text-left">
            <Link className="block py-2 md:py-0" to="/contact/">Contactez-nous</Link>
            <Link className="block py-2 md:py-0" to="/mentions-legales/">Mentions légales</Link>
          </div>
        </div>
        <div className="text-center mt-16 mb-6 opacity-50">© {new Date().getFullYear()} École de conduite Yvan Blin</div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
