import React, { useState } from 'react';
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from '@reach/router';

import menu from "../images/icons/menu.svg"
import close from "../images/icons/close.svg"

const Header = () => {

  const [menuVisible, setMenuVisible] = useState(false);


  return (
    <header className="flex justify-between py-6">
      <div>
        <Link to="/">
          <StaticImage
            src="../images/logo.png"
            placeholder="blurred"
            layout="constrained"
            width={45}
            height={45}
            alt="Logo - Yvan Blin"
          />
        </Link>
      </div>

      <button className="absolute right-6 top-6 md:hidden" onClick={() => setMenuVisible(true)}>
        <img
          src={menu}
          width="30"
          alt=""
        />
      </button>

      <div className={`${menuVisible ? 'block' : 'hidden'} fixed top-0 left-0 px-12 pt-24 w-full h-full bg-dark z-10
        md:space-x-6 md:flex md:relative md:px-0 md:pt-0 md:w-auto md:h-auto md:bg-transparent `}
      >
      <button className="absolute right-6 top-6 md:hidden" onClick={() => setMenuVisible(false)}>
        <img
          src={close}
          width="30"
          alt=""
        />
      </button>
        <Link
          className={`block py-3
            md:inline-block md:py-0 hover:text-primary`}
          to="/l-auto-ecole/"
          activeClassName="active"
        >
          L'auto-école
        </Link>
        <Link
          className={`block py-3
            md:inline-block md:py-0 hover:text-primary ${useLocation().pathname.includes('/les-horaires/') ? 'active' : ''}`}
          to="/les-horaires/tests-de-code"
          activeClassName="active"
        >
          Les horaires
        </Link>
        <Link
          className={`block py-3
            md:inline-block md:py-0 hover:text-primary`}
          to="/nos-formations/"
          activeClassName="active"
          partiallyActive={true}
        >
          Nos formations
        </Link>
        <Link
          className={`block py-3
            md:inline-block md:py-0 hover:text-primary`}
          to="/le-label/"
          activeClassName="active"
        >
          Le Label
        </Link>
        <Link
          className={`block py-3
            md:inline-block md:py-0 hover:text-primary`}
          to="/contact/"
          activeClassName="active"
        >
          Contactez-nous
        </Link>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
