const schemaGenerator = ({
  breadcrumb,
  name,
  defaultTitle,
  title,
  description,
  siteUrl,
  url,
}) => {
  const orgId = `${siteUrl}/#organization`
  const websiteId = `${siteUrl}/#website`
  const breadcrumbId = `${siteUrl}/#breadcrumb`

  if (url.endsWith("/")) url = url.slice(0, -1) // remove trailing slash

  const globalGraph = [
    {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": orgId,
          name: name,
          url: siteUrl,
          logo: {
            "@type": "ImageObject",
            "@id": `${siteUrl}/#logo`,
            url: siteUrl + "/social-networks.jpg",
          },
        },
        {
          "@type": "WebSite",
          "@id": websiteId,
          url: siteUrl,
          name: defaultTitle,
          publisher: { "@id": orgId },
          inLanguage: "fr-FR",
        },
        {
          "@type": "WebPage",
          "@id": `${url}/#webpage`,
          url: url,
          name: title,
          isPartOf: { "@id": websiteId },
          description: description,
          inLanguage: "fr-FR",
          breadcrumb: { "@id": breadcrumbId },
          potentialAction: [
            {
              "@type": "ReadAction",
              target: [url],
            },
          ],
        },
        {
          "@type": "BreadcrumbList",
          "@id": breadcrumbId,
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@type": "WebPage",
                "@id": `${siteUrl}/#webpage`,
                url: siteUrl,
                name: "Accueil",
              },
            },
          ],
        },
      ],
    },
  ]

  // Add breadcrumb items
  const graph = globalGraph[0]["@graph"];

  if (breadcrumb) {
    let fullUrl = siteUrl;
    breadcrumb.forEach((el, i) => {
      fullUrl += `/${el.url}`;
      const breadcrumb = {
        "@type": "ListItem",
        position: i + 2,
        item: {
          "@type": "WebPage",
          "@id": `${fullUrl}/#webpage`,
          url: fullUrl,
          name: el.name,
        },
      }
      graph
        .find(el => el["@type"] === "BreadcrumbList")
        .itemListElement.push(breadcrumb)
    });
  }

  return globalGraph
}

export default schemaGenerator
